import axios from "axios"

const state = {}

const getters = {}

const actions = {
	updatePrimaryVendor({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/purchasing/product/${data.stockitem_id}/primary-vendor/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createOrder({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/purchasing/order/create/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	deleteOrder({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.delete(`/api/pharmacy/purchasing/order/${data.id}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	deleteMultipleOrders({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.post(`/api/pharmacy/purchasing/orders/delete/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}