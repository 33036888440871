import { capitalize } from "./capitalize"
import { uppercase } from "./uppercase"

// Main plugin for installing all directive plugins
const directives = {
	capitalize,
	uppercase
}

import { registerDirectives } from "bootstrap-vue/src/utils/plugins"

export default {
	install(Vue) {
		registerDirectives(Vue, directives)
	}
}
