import axios from "axios"

const state = {}

const getters = {}

const actions = {
	getStockStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/assortment/statistics/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDistributedStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/assortment/distributed/statistics/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterExpiredProducts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/assortment/expired/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInventoryLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/inventory/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterOrderitemLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/orders/orderitem/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterBTWLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/assortment/btw/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getCustomPrescriptionStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/prescription/statistics/custom/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyPrescriptionStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/prescription/statistics/daily/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getMonthlyPrescriptionStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/prescription/statistics/monthly/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getYearlyPrescriptionStatistics({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/prescription/statistics/yearly/get/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterMonitoringLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/reports/medication-monitoring/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}