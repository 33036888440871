<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: "app",
	watch: {
		$route: {
			immediate: true,
			handler(to, from) {
				document.title = "GemRx"
			},
		},
	},
}
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/free.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "./assets/fontawesome/webfonts";
@import "./assets/fontawesome/scss/fontawesome.scss";
@import "./assets/fontawesome/scss/solid.scss";
@import "./assets/fontawesome/scss/regular.scss";
@import "./assets/fontawesome/scss/light.scss";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";

/* Import Main styles for this application  */
@import "./assets/scss/style";
</style>
