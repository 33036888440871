import Vue from "vue"

export default Vue.extend({
	props: {
		id: {
			default() {
				return ""
			}
		},
		stacked: {
			default() {
				return false
			}
		},
		fixed: {
			default() {
				return false
			}
		},
		responsive: {
			default(){
				return true
			}
		},
		captionTop: {
			default() {
				return false
			}
		},
		small: {
			default() {
				return true
			}
		},
		headVariant: {
			default() {
				return null
			}
		},
		bordered: {
			default() {
				return true
			}
		},
		borderless: {
			default() {
				return false
			}
		},
		outlined: {
			default() {
				return true
			}
		},
		striped: {
			default() {
				return false
			}
		},
		dark: {
			default() {
				return false
			}
		},
		rowClass: {
			type: Function
		},
		hover: {
			default() {
				return true
			}
		},
		selectable: {
			default() {
				return true
			}
		},
		selectMode: {
			default() {
				return "single"
			}
		},
		showFilters: {
			default() {
				return true
			}
		},
		showItemsPerPage: {
			default() {
				return true
			}
		},
		showItemsNavigation: {
			default() {
				return true
			}
		},
		sortBy: {
			default() {
				return "id"
			}
		},
		sortDesc: {
			default() {
				return false
			}
		},
		setLastIndexOnRefresh: {
			default() {
				return false
			}
		},
		defaultPerPage: {
			default() {
				return 10
			}
		},
		pageOptions: {
			default() {
				return [1, 5, 10, 15, 20, 50, 100]
			}
		},
		navLabel: {
			default() {
				return "items"
			},
			type: String
		},
		fields: {
			default() {
				return []
			},
			required: true
		},
		customSlots: {
			default() {
				return []
			}
		},
		filters: {
			default() {
				return {}
			}
		},
		items: {
			default() {
				return []
			}
		},
		busy: {
			default() {
				return false
			}
		}
	},
	data() {
		return {
			currentPage: 1,
			perPage: this.defaultPerPage,
			isBusy: this.busy,
			sortedBy: this.sortBy,
			sortedDesc: this.sortDesc,
			lastIndex: -1
		}
	},
	watch: {
		busy(newVal, oldVal) {
			// watch it
			this.isBusy = newVal
		},
	},
	methods: {
		isRowSelected(index) {
			return this.$refs.table.isRowSelected(index)
		},
		rowClicked(item, index) {
			if (this.isRowSelected(index)) {
				this.lastIndex = -1
			} else {
				this.lastIndex = index
			}

			this.$emit("row-clicked", item, index)
		},
		onRowSelected(items) {
			if (items[0]) {
				this.$emit("item-selected", items[0])
			} else {
				this.$emit("item-deselected")
			}
		},
		onRefreshed() {
			// this.isBusy = false
			if (this.setLastIndexOnRefresh) {
				if (this.lastIndex !== -1) {
					this.setLastselectedRow()
				}
			}
		},
		filter() {
			this.refreshTable()
		},
		refreshTable() {
			this.$refs.table.refresh()
		},
		clearSelected() {
			this.$refs.table.clearSelected()
		},
		selectRow(index) {
			this.lastIndex = index
			this.$refs.table.selectRow(index)
		},
		unselectRow(index) {
			this.$refs.table.unselectRow(index)
		},
		async setLastselectedRow() {
			await new Promise((resolve) => setTimeout(resolve, 500))
			this.selectRow(this.lastIndex)
		},
		getLastselectedRow() {
			return this.selectRow(this.lastIndex)
		},
		getSelectedRow() {
			return this.lastIndex
		}
	}
})
