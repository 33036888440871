import axios from "axios"

const actions = {
	// PRODUCTS
	testConnection({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/test/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendGP({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/product/gp/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendOTC({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/product/otc/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendWP({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/product/wp/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendStockUpdate({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/communications/send/stockitem/${data.stockitem_id}/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendCreatedPatient({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/patient/created/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendUpdatedPatient({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/communications/send/patient/updated/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	sendCreatedPrescription({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/communications/send/prescription/${data.id}/created/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

export default {
	namespaced: true,
	actions,
}
