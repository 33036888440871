import axios from "axios"

const state = {
	doses: [],
	frequencies: [],
	shortcodes_frequency: [],
	shortcodes_dose: [],
	shortcodes_extra: [],
	logtypes: [],
	actiontypes: [],

	settings: [],
}

const getters = {
	getMedicationMonitoring: (state) => () => {
		return state.settings.find((setting) => setting.name == "MEDICATION MONITORING")
	} ,
	getDoseById: (state) => (id) => {
		return state.doses.find((dose) => dose.id == id)
	},
	getDoseByName: (state) => (name) => {
		return state.doses.find((dose) => dose.name == name)
	},
	getFrequencyById: (state) => (id) => {
		return state.frequencies.find((frequency) => frequency.id == id)
	},
	getFrequencyByName: (state) => (name) => {
		return state.frequencies.find((frequency) => frequency.name == name)
	},
	getShortcodeFrequencyByCode: (state) => (code) => {
		return state.shortcodes_frequency.find((shortcode) => shortcode.code == code)
	},
	getShortcodeDoseByCode: (state) => (code) => {
		return state.shortcodes_dose.find((shortcode) => shortcode.code == code)
	},
	getShortcodeExtraByCode: (state) => (code) => {
		return state.shortcodes_extra.find((shortcode) => shortcode.code == code)
	}
}

const actions = {
	filterMonitoringLog({commit}, data){
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/monitoring/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	logMonitoringAction({commit}, data){
		return new Promise((resolve, reject)=> {
			axios
				.put("/api/medications/monitoring/log/actions/", data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getMonitoringSettings({commit}){
		return new Promise((resolve, reject)=> {
			axios
				.get("/api/medications/monitoring/settings/get/")
				.then((response)=> {
					const settings = response.data
					resolve(settings)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getSettings({commit}){
		return new Promise((resolve, reject)=> {
			axios
				.get("/api/medications/settings/get/")
				.then((response)=> {
					const settings = response.data
					resolve(settings)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDoses({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/doses/get/")
				.then((response) => {
					const doses = response.data
					commit("set_doses", doses)
					resolve(doses)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getFrequencies({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/frequencies/get/")
				.then((response) => {
					const frequencies = response.data
					commit("set_frequencies", frequencies)
					resolve(frequencies)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getShortcodesFrequency({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/shortcodes/frequency/get/")
				.then((response) => {
					const shortcodes_frequency = response.data
					commit("set_shortcodes_frequency", shortcodes_frequency)
					resolve(shortcodes_frequency)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getShortcodesDose({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/shortcodes/dose/get/")
				.then((response) => {
					const shortcodes_dose = response.data
					commit("set_shortcodes_dose", shortcodes_dose)
					resolve(shortcodes_dose)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getShortcodesExtra({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/shortcodes/extra/get/")
				.then((response) => {
					const shortcodes_extra = response.data
					commit("set_shortcodes_extra", shortcodes_extra)
					resolve(shortcodes_extra)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getLogTypes({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/logtypes/get/")
				.then((response) => {
					const logtypes = response.data
					commit("set_logtypes", logtypes)
					resolve(logtypes)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getActionTypes({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/medications/actiontypes/get/")
				.then((response) => {
					const actiontypes = response.data
					commit("set_actiontypes", actiontypes)
					resolve(actiontypes)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
	set_settings(state, settings) {
		state.settings = settings
	},
	set_doses(state, doses) {
		state.doses = doses
	},
	set_frequencies(state, frequencies) {
		state.frequencies = frequencies
	},
	set_shortcodes_frequency(state, shortcodes_frequency) {
		state.shortcodes_frequency = shortcodes_frequency
	},
	set_shortcodes_dose(state, shortcodes_dose) {
		state.shortcodes_dose = shortcodes_dose
	},
	set_shortcodes_extra(state, shortcodes_extra) {
		state.shortcodes_extra = shortcodes_extra
	},
	set_logtypes(state, logtypes) {
		state.logtypes = logtypes
	},
	set_actiontypes(state, actiontypes) {
		state.actiontypes = actiontypes
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
