<template>
	<b-form-input autocomplete="off" ref="input" v-bind="$attrs" :value="formattedValue" v-currency="options" @change="onChange" @input="onInput" @focus="$emit('focus')"></b-form-input>
</template>

<script>
export default {
	name: "PercentInputField",
	inheritAttrs: true,
	props: {
		value: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			formattedValue: null,

			locale: "en-EN",
			selectedCurrencyOption: 0,
			currencyCode: null,
			distractionFree: true,
			hideCurrencySymbol: true,
			hideGroupingSymbol: true,
			hideNegligibleDecimalDigits: true,
			prefix: null,
			suffix: "%",
			precisionEnabled: false,
			precisionRangeEnabled: false,
			precisionFixed: 2,
			precisionRange: [0, 20],
			valueRangeEnabled: true,
			valueRange: [0, 100],
			minActive: false,
			maxActive: false,
			autoDecimalMode: true,
			valueAsInteger: false,
			allowNegative: false,
		}
	},
	computed: {
		options() {
			return {
				locale: this.locale,
				currency: [this.currencyCode, null, { prefix: this.prefix, suffix: this.suffix }][this.selectedCurrencyOption],
				valueRange: this.valueRangeEnabled ? { min: this.valueRange[0], max: this.valueRange[1] } : undefined,
				precision: this.precisionEnabled ? (this.precisionRangeEnabled ? { min: this.precisionRange[0], max: this.precisionRange[1] } : this.precisionFixed) : undefined,
				distractionFree: this.distractionFree
					? {
							hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
							hideCurrencySymbol: this.hideCurrencySymbol,
							hideGroupingSymbol: this.hideGroupingSymbol,
					  }
					: false,
				autoDecimalMode: this.autoDecimalMode,
				valueAsInteger: this.valueAsInteger,
				allowNegative: this.allowNegative,
			}
		},
	},
	watch: {
		value(value) {
			this.setValue(value)
		},
	},
	mounted() {
		this.setValue(this.value)
	},
	methods: {
		setValue(value) {
			this.$ci.setValue(this.$refs.input, value)
		},
		onInput(value) {
			this.$emit("input", this.$ci.getValue(this.$refs.input))
			this.formattedValue = value
		},
		onChange(value) {
			this.$emit("change", this.$ci.getValue(this.$refs.input))
			this.formattedValue = value
		},
	},
}
</script>
