import store from "@/store"
import i18n from '@/i18n'

// Nested Route Middleman
const Middleman = () => import("@/views/pharmacy/help/Middleman")

// Containers
const StockroomContainer = () => import("@/views/pharmacy/stockroom/container/DefaultContainer")

// Views
const StockroomIndex = () => import("@/views/auth/Index")
const StockroomAssortments = () => import("@/views/pharmacy/stockroom/views/Assortments")
const StockroomInventory = () => import("@/views/pharmacy/stockroom/views/Inventory")
const StockroomReports = () => import("@/views/pharmacy/stockroom/views/Reports")
const StockroomOrders = () => import("@/views/pharmacy/stockroom/views/Orders")
const StockroomBatchManagement = () => import("@/views/pharmacy/stockroom/views/BatchManagement")
const StockroomTransferInternal = () => import("@/views/pharmacy/stockroom/views/TransferInternal")
// const StockroomTransferBranches = () => import("@/views/pharmacy/stockroom/views/TransferBranches")
const StockroomTransferRequestRequest = () => import("@/views/pharmacy/stockroom/views/TransferRequest")
const StockroomTransferRequestOrder = () => import("@/views/pharmacy/stockroom/views/TransferOrder")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

export default [
    {
      path: "/stockroom",
      component: StockroomContainer,
      meta: {
        label: i18n.t('routes.stockroom'),
      },
      children: [
        {
          path: "",
          component: StockroomIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["stockroom:viewer", "stockroom:editor"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortments",
          component: StockroomAssortments,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["assortments:viewer", "assortments:editor"],
            label: i18n.tc('routes.assortments', 2),
          },
        },
         {
          path: "inventory",
          component: StockroomInventory,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["stockroom:viewer", "stockroom:editor"],
            label: i18n.t('routes.inventory'),
          },
        },
        {
          path: "reports",
          component: StockroomReports,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["report:viewer"],
            label: i18n.tc('routes.reports', 2),
          },
        },
        {
          path: "orders",
          component: StockroomOrders,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["stockroom:viewer", "stockroom:editor"],
            label: i18n.tc('routes.orders', 2),
          },
        },
        {
          path: "batches",
          component: StockroomBatchManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["stockroom:viewer", "stockroom:editor"],
            label: i18n.t('routes.batch-management'),
          },
        },
        {
          path: "transfers",
          component: Middleman,
          redirect:  '/',
          meta: {
            roleRequired: ["stockroom:viewer", "stockroom:editor"],
            label: i18n.tc('routes.transfers'),
          },
          children: [
            {
              path: "internal",
              component: StockroomTransferInternal,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["stockroom:viewer", "stockroom:editor"],
                label: i18n.t('routes.internal'),
              },
            },
            // {
            //   path: "branches",
            //   component: StockroomTransferBranches,
            //   beforeEnter: requireAuthenticated,
            //   meta: {
            //     roleRequired: ["stockroom:viewer", "stockroom:editor"],
            //     label: "Branches",
            //   },
            // },
            {
              path: "request",
              component: StockroomTransferRequestRequest,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["stockroom:viewer", "stockroom:editor"],
                label: i18n.t('routes.request'),
              },
            },
            {
              path: "order",
              component: StockroomTransferRequestOrder,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["stockroom:viewer", "stockroom:editor"],
                label: i18n.tc('routes.orders', 2),
              },
            },
          ]
        },
      ],
    },
]
