import store from "@/store"
import i18n from '@/i18n'

// Containers
const AdministrationContainer = () => import("@/views/pharmacy/administration/container/DefaultContainer")

// Views
const AdministrationIndex = () => import("@/views/auth/Index")
const AdministrationAssortments = () => import("@/views/pharmacy/administration/views/Assortments")
const AdministrationNewProduct = () => import("@/views/pharmacy/administration/views/NewProduct")
const AdministrationPatients = () => import("@/views/pharmacy/administration/views/Patients")
const AdministrationNewPatients = () => import("@/views/pharmacy/administration/views/NewPatient")
const AdministrationInventory = () => import("@/views/pharmacy/administration/views/Inventory")
const AdministrationReports = () => import("@/views/pharmacy/administration/views/Reports")
const AdministrationTransfers = () => import("@/views/pharmacy/administration/views/Transfers")
const AdministrationPrescriptions = () => import("@/views/pharmacy/administration/views/Prescriptions")
const AdministrationCashregisters = () => import("@/views/pharmacy/administration/views/Cashregisters")
const AdministrationFinancialBalance = () => import("@/views/pharmacy/administration/views/FinancialBalance")
const AdministrationInvoices = () => import("@/views/pharmacy/administration/views/Invoices")
const AdministrationInvoiceDetails = () => import("@/views/pharmacy/administration/views/InvoiceDetails")
const AdministrationDebitPayments = () => import("@/views/pharmacy/administration/views/DebitPayments")
const AdministrationBatchManagement = () => import("@/views/pharmacy/administration/views/BatchManagement")
const AdministrationPriceManagement = () => import("@/views/pharmacy/administration/views/PriceManagement")
const AdministrationCustomTransfer = () => import("@/views/pharmacy/administration/views/CustomTransfer")
const AdministrationPatientsManagement = () => import("@/views/pharmacy/administration/views/PatientsManagement")
const AdministrationCurrencyManagement = () => import("@/views/pharmacy/administration/views/CurrencyManagement")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
  store.dispatch('user/current').then(() => {
    if (!store.getters['user/isAuthenticated']) {
      if (to.fullPath === '/') {
        next({ path: '/login', replace: true })
      }else{
        next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
      }
    } else {
      if (to.matched.some((m) => m.meta.roleRequired)) {
        guardRoute(to, from, next)
      } else {
        next()
      }
    }
  })
}

export default [
    {
      path: "/administration",
      component: AdministrationContainer,
      meta: {
        label: i18n.t('routes.administration'),
      },
      children: [
        {
          path: "",
          component: AdministrationIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:viewer", "administration:editor"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortments",
          component: AdministrationAssortments,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["assortments:viewer", "assortments:editor"],
            label: i18n.tc('routes.assortments', 2),
          },
        },
                {
          path: "assortments/create",
          component: AdministrationNewProduct,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: "assortments:editor",
            label: i18n.t('routes.new-product'),
          },
        },
        {
          path: "patients",
          component: AdministrationPatients,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["patients:viewer", "patients:editor"],
            label: i18n.tc('routes.patients', 2),
          },
        },
        {
          path: "patients/create",
          component: AdministrationNewPatients,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["patients:editor"],
            label: i18n.t('routes.new-patients'),
          },
        },
        {
          path: "inventory",
          component: AdministrationInventory,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:viewer", "administration:editor"],
            label: i18n.t('routes.inventory'),
          },
        },
        {
            path: "reports",
            component: AdministrationReports,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired: ["report_viewer"],
                label: i18n.tc('routes.reports', 2),
            },
        },
        {
            path: "transfers",
            component: AdministrationTransfers,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.tc('routes.transfers'),
            },
        },
        {
            path: "prescriptions",
            component: AdministrationPrescriptions,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.tc('routes.prescriptions', 2),
            },
        },
        {
            path: "cashregisters",
            component: AdministrationCashregisters,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.tc('routes.cashregister', 2),
            },
        },
        {
            path: "financial-balance",
            component: AdministrationFinancialBalance,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.t('routes.financial-balance'),
            },
        },
        {
            path: "invoices",
            component: AdministrationInvoices,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.tc('routes.invoices', 2),
            },
        },
        {
            path: "invoices/:invoice/details",
            component: AdministrationInvoiceDetails,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.t('routes.invoice-details'),
            },
        },
        {
            path: "debit-payments",
            component: AdministrationDebitPayments,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.t('routes.debit-payments'),
            },
        },
        {
          path: "batches",
          component: AdministrationBatchManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:viewer", "administration:editor"],
            label: i18n.t('routes.batch-management'),
          },
        },
        {
          path: "prices",
          component: AdministrationPriceManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:viewer", "administration:editor"],
            label: i18n.t('routes.price-management'),
          },
        },
        {
            path: "custom-transfer",
            component: AdministrationCustomTransfer,
            beforeEnter: requireAuthenticated,
            meta: {
                roleRequired:["administration:viewer", "administration:editor"],
                label: i18n.t('routes.custom-transfer'),
            },
        },
        {
          path: "patients/management",
          component: AdministrationPatientsManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:viewer", "administration:editor"],
            label: i18n.t('routes.patients-management'),
          },
        },
        {
          path: "currency",
          component: AdministrationCurrencyManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["administration:editor"],
            label: i18n.t('routes.currency'),
          },
        },
      ],
    },
]
