import { BaseTable, ModelTable } from "./table"
import { CurrencyInputField, PercentInputField, NewDatePicker, FormGroup, FileUploadDropBox, MultipleFileUploadDropBox, FileUploadNaked, FileUploadPlain, SelectBox, WorkerCode } from "./form"
import { DialogOverlay } from "./overlay"

import Loading from "vue-loading-overlay"

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

import { registerComponents } from "bootstrap-vue/src/utils/plugins"

const components = {
	CurrencyInputField,
	PercentInputField,
	NewDatePicker,
	BaseTable,
	ModelTable,
	FormGroup,
	FileUploadDropBox,
	MultipleFileUploadDropBox,
	FileUploadPlain,
	FileUploadNaked,
	SelectBox,
	WorkerCode,
	VueTypeaheadBootstrap,
	DialogOverlay,
	Loading,
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}
