import store from "@/store"
import i18n from '@/i18n'

// Containers
const CashRegisterContainer = () => import("@/views/pharmacy/cashregister/container/DefaultContainer")

// Views
const CashRegisterIndex = () => import("@/views/pharmacy/cashregister/views/CashRegister")
const CashRegisterSession = () => import("@/views/pharmacy/cashregister/views/CashRegisterSession")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

const requireShiftStarted = (to, from, next) => {
  store.dispatch('pharmacy/cashregister/getCashRegister')
    .then(() => {
      if (store.getters['pharmacy/cashregister/shiftStarted']) {
        next()
      } else {
        next('/logout', { replace: true })
      }
    })
}

const requireShiftEnded = (to, from, next) => {
  store.dispatch('pharmacy/cashregister/getCashRegister')
    .then(() => {
      if (store.getters['pharmacy/cashregister/shiftStarted']) {
        next('/cashregister/session', { replace: true })
      } else {
        next()
      }
    })
}

export default [
    {
      path: "/cashregister",
      component: CashRegisterContainer,
      meta: {
        label: i18n.tc('routes.cashregister'),
      },
      children: [
        {
          path: "",
          component: CashRegisterIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            requiresAuth: true,
            roleRequired: ["cashregister:viewer","cashregister:editor"],
            label: i18n.t('routes.welcome'),
          },
           beforeEnter: requireShiftEnded,
        },
        {
          path: "session",
          component: CashRegisterSession,
          beforeEnter: requireShiftStarted,
          meta: {
            requiresAuth: true,
            roleRequired: ["cashregister:viewer","cashregister:editor"],
            label: i18n.t('routes.session'),
          },
        },
      ]
    },
]
