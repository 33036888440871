import axios from "axios"

const state = {
	patient: null,
	insurances: [],
	insurance: null,
}

const getters = {
	getPatientInsuranceById: (state) => (id) => {
		return state.insurances.find((insurance) => insurance.id == id)
	} ,
}

const actions = {
	filterReservedPrescription({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/frontdesk/prescription/reserved/filter/`, {
					params:data
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createPrescription({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/patient/${data.form.patient}/prescription/create/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	bookReservedPrescription({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/frontdesk/reserved/prescription/${id}/book/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deleteReservedPrescription({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/frontdesk/reserved/prescription/${id}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	bookReservedPrescriptionItem({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/frontdesk/reserved/prescription/item/${id}/book/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deleteReservedPrescriptionItem({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/frontdesk/reserved/prescription/item/${id}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPrescriptionLabels({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/prescription/${data.prescription}/labels/print/`, data.options)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPrescriptionitemLabel({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/prescription/item/${data.prescriptionitem}/labels/print/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPreparingRepetition({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/prescription/repetition/print/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPreparedRepetition({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/prescription/${data.id}//repetition/print/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPreparingTranscript({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/frontdesk/prescription/transcript/print/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printPreparedTranscript({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/frontdesk/prescription/${data.id}/transcript/print/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
	set_patient(state, patient) {
		state.patient = patient
		patient.insurances.primary ? state.insurances.push(patient.insurances.primary) : null
		patient.insurances.secondary ? state.insurances.push(patient.insurances.secondary) : null
	},
	unset_patient(state) {
		state.patient = null
		state.insurances = []
	},
	set_insurance(state, insurance) {
		state.insurance = insurance
	},
	unset_insurance(state) {
		state.insurance = null
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}