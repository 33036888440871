<template>
	<b-form-input autocomplete="off" ref="input" v-bind="$attrs" :value="formattedValue" v-currency="options" @change="onChange" @input="onInput" @focus="$emit('focus')"></b-form-input>
</template>

<script>
export default {
	name: "CurrencyInputField",
	inheritAttrs: true,
	props: {
		value: {
			type: Number,
			default: null,
		},
		options: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			formattedValue: null,
		}
	},
	watch: {
		value(value) {
			this.setValue(value)
		},
	},
	mounted() {
		this.setValue(this.value)
	},
	methods: {
		setValue(value) {
			this.$ci.setValue(this.$refs.input, value)
		},
		onInput(value) {
			this.$emit("input", this.$ci.getValue(this.$refs.input))
			this.formattedValue = value
		},
		onChange(value) {
			this.$emit("change", this.$ci.getValue(this.$refs.input))
			this.formattedValue = value
		},
	},
}
</script>
