import Vue from "vue"
import Vuex from "vuex"

import general from "@/store/modules/general"
import user from "@/store/modules/user"
import patients from "@/store/modules/patients"
import pharmacy from "@/store/modules/pharmacy"
import medications from "@/store/modules/medications"
import communications from "@/store/modules/communications"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		general,
		user,
		patients,
		pharmacy,
		medications,
		communications
	}
})
