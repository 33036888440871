import { mapState, mapGetters } from "vuex"

export default {
    computed: {
        ...mapState("medications", {
			frequencies: (state) => state.frequencies,
			doses: (state) => state.doses,
			shortcodes_frequency: (state) => state.shortcodes_frequency,
			shortcodes_dose: (state) => state.shortcodes_dose,
			shortcodes_extra: (state) => state.shortcodes_extra,
		}),
		...mapGetters("medications", ["getShortcodeFrequencyByCode", "getShortcodeDoseByCode", "getShortcodeExtraByCode"]),
    },
    methods: {
        computeShortcode(shortcode) {
            try {
                const regex = /(\d{1,3}|\d{1,3}\.\d{1,2}|\d{1,3}[-]\d{1,3})([A-Z]{1,3})(\d{1,3}|\d{1,3}\.\d{1,2}|\d{1,3}[-]\d{1,3})([A-Z]{1,3})$/gm
                const matches = regex.exec(shortcode.toUpperCase())
    
                const frequency_amount = matches[1]
                const dose_amount = matches[3]
    
                const frequency = this.getShortcodeFrequencyByCode(matches[2]).frequency.id
                const dose = this.getShortcodeDoseByCode(matches[4]).dose.id

                return { frequency_amount: frequency_amount, dose_amount: dose_amount, frequency: frequency, dose: dose}

            } catch (error) {
                throw { shortcode: ["This shortcode does not exist"] }
            }
		},
        computeExtraShortcode(shortcodes) {
            try {
                const collection = []
                const list = shortcodes.split(" ")
                
                for (let i = 0; i < list.length; i++) { 
                    let tmp = ""
                    const sub = list[i].split("|")

                    for (let j = 0; j < sub.length; j++) {
                        let tmp_text = ""
                        const regex = /(\d{1,3}|\d{1,3}\.\d{1,2}|\d{1,3}[-]\d{1,3})([U]|[D]|[W]|[M])([A-Z]{1,5})|([A-Z]{1,5})$/gm
                        const matches = regex.exec(sub[j].toUpperCase())

                        if (matches[1] != undefined) {
                            const frequency = this.getShortcodeFrequencyByCode(matches[2]).frequency.name
                            const shortcode = this.getShortcodeExtraByCode(matches[3]).text

                            tmp_text = matches[1] + " maal " + frequency + " " + shortcode
                            tmp = tmp.concat(tmp_text)
                        } else {
                            tmp_text = this.getShortcodeExtraByCode(matches[4]).text
                            tmp = tmp.concat(tmp_text)
                        }
                    }
                    collection.push(tmp)
                }
                return this.combine(collection)
            } catch(error) {
				throw { extra_shortcode: ["This shortcode does not exist"] }

            }
        },
        combine(array) {
			try {
				let string

				if (array.length > 1) {
					string = array.join(". ").concat(".")
				} else {
					string = array.toString().concat(".")
				}

				if (string.length > this.maxLengthPerLineForAdditionalInfo) {
					let pos = string.lastIndexOf(" ")
					let firstline = string.substring(0, pos)
					let secondline = string.substring(pos + 1)

					return firstline.concat("\n").concat(secondline)
				} else {
					return string
				}
			} catch (error) {
				throw error
			}
		},
    }
}