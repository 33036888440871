<template>
	<b-card class="card-accent-info">
		<div slot="header">
			<i class="cui-people"></i> <strong> {{ $t("page-headers.workercode") }} </strong>
			<div class="card-header-actions">
				{{ worker ? worker.name : "" }}
			</div>
		</div>
		<b-form-group>
			<label class="sr-only" for="workerCode">{{ $t("fields.login.workercode") }}</label>
			<b-input-group prepend="Code" class="mb-2 mr-sm-2 mb-sm-0">
				<b-form-input autofocus autocomplete="off" ref="worker" type="password" :disabled="isValid" placeholder="eg. 0000" v-model="workerCode" @focus="endSession" debounce="1000" />
				<b-input-group-append v-show="isValid">
					<b-button variant="primary" @click="endSession" tabindex="-1"><i class="cui-account-logout"></i></b-button>
				</b-input-group-append>
			</b-input-group>
			<!-- <small v-show="!isValid" style="color:#ff0000;">Workercode is incorrect. Try again.</small> -->
		</b-form-group>
	</b-card>
</template>
<script>
import _ from "lodash"
import { mapState, mapActions } from "vuex"

export default {
	name: "worker-code",
	data() {
		return {
			workerCode: null,
		}
	},
	watch: {
		workerCode(newCode, oldCode) {
			this.$emit("on-loading", true)
			this.startSession()
			// this.debouncedStartSession()
		},
		worker: {
			immediate: true,
			deep: true,
			handler(val) {
				if (Object.entries(val).length === 0) {
					this.workerCode = null
				}
			},
		},
	},
	computed: {
		...mapState("pharmacy", {
			worker: (state) => state.worker,
		}),
		isValid() {
			return Object.entries(this.worker).length !== 0 && this.workerCode ? true : false
		},
	},
	methods: {
		...mapActions("pharmacy", ["startSessionWorker", "endSessionWorker"]),
		startSession() {
			if (!this.workerCode || this.workerCode.length < 2) {
				this.$emit("on-loading", false)
				this.$emit("code-result", false)

				return
			}
			this.startSessionWorker(this.workerCode)
				.then(() => {
					this.$emit("code-result", true)
				})
				.catch((errors) => {
					this.$emit("code-result", false)
				})
				.finally(() => {
					this.$emit("on-loading", false)
				})
		},
		endSession() {
			this.endSessionWorker()
				.then((response) => {
					this.workerCode = null
					this.$emit("code-result", false)
				})
				.finally(() => {
					this.$emit("on-loading", false)
					this.$emit("session-end")
				})
		},
	},
	created() {
		this.debouncedStartSession = _.debounce(this.startSession, 1000)
		// this.endSession() // end the previous worker session on the server if there is one
	},
	mounted() {
		this.$refs.worker.focus()
	},
}
</script>
