import store from "@/store"
import i18n from '@/i18n'

// Containers
const PurchasingContainer = () => import("@/views/pharmacy/purchasing/container/DefaultContainer")

// Views
const PurchasingIndex = () => import("@/views/auth/Index")
const PurchasingAssortments = () => import("@/views/pharmacy/purchasing/views/Assortments")
const PurchasingNewProduct = () => import("@/views/pharmacy/purchasing/views/NewProduct")
const PurchasingReports = () => import("@/views/pharmacy/purchasing/views/Reports")
const PurchasingOrders = () => import("@/views/pharmacy/purchasing/views/Orders")
const PurchasingCurrencyManagement = () => import("@/views/pharmacy/purchasing/views/CurrencyManagement")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
} 

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

export default [
    {
      path: "/purchasing",
      component: PurchasingContainer,
      meta: {
        label: i18n.t('routes.purchasing'),
      },
      children: [
        {
          path: "",
          component: PurchasingIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["purchasing:viewer", "purchasing:editor"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortments",
          component: PurchasingAssortments,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["assortments:viewer", "assortments:editor"],
            label: i18n.tc('routes.assortments', 2),
          },
        },
        {
          path: "assortments/create",
          component: PurchasingNewProduct,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: "assortments:editor",
            label: i18n.t('routes.new-product'),
          },
        },
        {
          path: "reports",
          component: PurchasingReports,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["report:viewer"],
            label: i18n.tc('routes.reports', 2),
          },
        },
        {
          path: "orders",
          component: PurchasingOrders,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["purchasing:viewer", "purchasing:editor"],
            label: i18n.tc('routes.orders', 2),
          },
        },
        {
          path: "currency",
          component: PurchasingCurrencyManagement,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["purchasing:editor"],
            label: i18n.t('routes.currency'),
          },
        },
      ],
    },
]
