import axios from "axios"

const state = {
    current: {},
}

const getters = {}

const actions = {
	createCurrencyRate({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post("/api/pharmacy/currency/rate/create/", data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	}, 
	filterCurrencyRates({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/currency/rate/filter/`, {
					params:data
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}