import axios from "axios"

const state = {
    current: {},
}

const getters = {}

const actions = {
	createNewPatient({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post("/api/pharmacy/patients/create/", data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updatePatient({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/patients/${data.demographics.id}/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updatePatientInsurances({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/patients/${data.patient}/insurances/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updatePatientMemo({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/patients/${data.patient}/memo/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterPatientHistory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/patients/prescription/history/filter/`, {
					params:data
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterPatientItemHistory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/patients/prescription/item/history/filter/`, {
					params:data
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	validatePatientMerge({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post("/api/pharmacy/patients/merge/validate/", data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	mergePatients({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post("/api/pharmacy/patients/merge/", data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	filterMergeLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/patients/merge/logs/filter/`, {
					params:data
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}