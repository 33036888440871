import Errors from './Errors'

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    deep_set(data, exclude=[]) {
        if(data) {
            Object.keys(data).map((property) => {
                if(!exclude.includes(property)) {
                    if(data[property]) {
                        if (property in this.originalData) {
                            if(typeof data[property] === 'object' && Object.keys(data[property]).length > 0) {
                                for (const [key, value] of Object.entries(data[property])) {
                                    if (this.originalData[property]) {
                                        if (key in this.originalData[property]) {
                                            this[property][key] = value
                                        }
                                    }
                                }
                             }else{
                                this[property] = data[property]
                             }
                        }else if(typeof data[property] === 'object' && Object.keys(data[property]).length > 0) {
                            for (const [key, value] of Object.entries(data[property])) {                                
                                if (key in this.originalData) {
                                    this[key] = value
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    set(data, exclude=[]) {
        if(data) {
            Object.keys(data).map((property) => {
                if(!exclude.includes(property)) {
                    // if(data[property]) {
                        this[property] = data[property]
                    // }
                }
            })
        }else{
            this.reset()
        }
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields with default data.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = this.originalData[field];
        }

        this.errors.clear();
    }

    /**
     * Set the form fields with default data.
     */
    default(data) {
        Object.keys(data).map((property) => {
            if (property in this.originalData) {
                this[property] = data[property]
            }
        })
        
        this.errors.clear();
    }
}

export default Form
