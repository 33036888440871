<template>
	<b-form-select ref="select" :id="id" :name="name" :disabled="disabled" :size="size" :plain="plain" :value="value" @input="$emit('input', $event)" @change="onChange" :state="state">
		<template v-slot:first>
			<slot name="slot-first">
				<option v-if="showFirst" :value="first_key_value" :disabled="first_disabled">{{ first_key_name }}</option>
			</slot>
		</template>
		<option v-for="(item, index) in items" :key="index" :value="getItemValue(item)">{{ getItemName(item) }}</option>
	</b-form-select>
</template>
<script>
export default {
	name: "select-box",
	props: {
		id: {
			required: true,
		},
		value: {
			default() {
				return null
			},
			required: true,
		},
		name: {
			default() {
				return ""
			},
		},
		state: {
			default() {
				return null
			},
		},
		disabled: {
			default() {
				return false
			},
		},
		items: {
			default() {
				return []
			},
			required: true,
		},
		size: {
			default() {
				return "md"
			},
		},
		plain: {
			default() {
				return true
			},
		},
		showFirst: {
			default() {
				return true
			},
		},
		first_disabled: {
			default() {
				return true
			},
		},
		first_key_value: {
			default() {
				return null
			},
		},
		first_key_name: "",
		key_value: {
			default() {
				return "id"
			},
		},
		key_name: {
			default() {
				return "name"
			},
		},
	},
	methods: {
		getItemValue(item) {
			if (item.hasOwnProperty(this.key_value)) {
				if (this.flatten) {
					return item[this.key_name]
				}
				return item[this.key_value]
			}
			return item
		},
		getItemName(item) {
			if (item.hasOwnProperty(this.key_value)) {
				return item[this.key_name]
			}
			return item
		},
		onChange(value) {
			this.$emit("change", value)
		},
	},
}
</script>
