import { mapGetters } from "vuex"

export default {
    computed: {
		...mapGetters("user", ["hasRole"]),
    },
    methods: {
        computeNavigation(features, items) {
            return items.filter(item => {
                let parentFeatureActive = false
                let parentHasRole = false
                
                if (item.hasOwnProperty("feature")) {
                    if (features.hasOwnProperty(item.feature) && features[item.feature]['state']) parentFeatureActive = true
                } else{
                    parentFeatureActive = true
                }
                
                if (item.hasOwnProperty("roles")) {
                    parentHasRole = item.roles.some((role) => { return this.hasRole(role)})
                } else {
                    parentHasRole = true
                }
                
                if (item.hasOwnProperty("children")) {
                    let activeChildren = []
                    item.children.filter(child => {
                        let childFeatureActive = false
                        let childHasRole = false

                        if (child.hasOwnProperty("feature")) {
                            if (features.hasOwnProperty(child.feature) && features[child.feature]['state']) childFeatureActive = true
                        } else {
                            childFeatureActive = true
                        }
                        
                        if (child.hasOwnProperty("roles")) {
                            childHasRole = child.roles.some((role) => { 
                                // console.log("CHILD SUB",child.name, role, this.hasRole(role))
                                return this.hasRole(role)
                            })
                        } else {
                            childHasRole = true
                        }
                        
                        // console.log("CHILD:", child.name, childFeatureActive, childHasRole, childFeatureActive && childHasRole)
                        if (childFeatureActive && childHasRole) activeChildren.push(child)
                        
                    })
                    item.children = activeChildren
                }
                // console.log("PARENT:", item.name, parentFeatureActive, parentHasRole, parentFeatureActive && parentHasRole)
                if (item.hasOwnProperty("children")) {
                    // console.log(item.children.length)
                    if (item.children.length > 0) {
                        if (parentFeatureActive && parentHasRole) return item
                    }
                } else {
                    if (parentFeatureActive && parentHasRole) return item
                }
            })
        }   
    }
}