<template>
    <b-button class="p-0" variant="link" size="sm">
        <b-form-file class="file-upload" ref="fileUpload" plain v-model="form.document" @input="onChange" :no-drop="true" size="sm" :accept="formats"/>
        <template v-if="isBusy">Uploading {{ form.document ? form.document.name : '' }}</template>
        <template v-else><slot/></template>
    </b-button>
</template>

<script>
import SingleFileUploadExtension from './SingleFileUploadExtension'

export default SingleFileUploadExtension.extend({
	name:'file-upload-naked',
})
</script>
<style scoped>
.file-upload input {
    cursor: pointer;
    height: 20px;
}

.file-upload {
    opacity: 0; /* invisible but it's there! */
    height: 20px;
    position: absolute;
    cursor: pointer;
}
</style>