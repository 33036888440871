import axios from "axios"

import purchasing from "@/store/modules/pharmacy/purchasing"
import stockroom from "@/store/modules/pharmacy/stockroom"
import patients from "@/store/modules/pharmacy/patients"
import frontdesk from "@/store/modules/pharmacy/frontdesk"
import cashregister from "@/store/modules/pharmacy/cashregister"
import administration from "@/store/modules/pharmacy/administration"
import currency from "@/store/modules/pharmacy/currency"
import pharmacist from "@/store/modules/pharmacy/pharmacist"
import reports from "@/store/modules/pharmacy/reports"
import shared from "@/store/modules/pharmacy/shared"

const state = {
	applications: {},
	feature_settings: {},
	monitoring_settings: {},
	worker: {},

	categories: [],
	units: [],
	wings: [],
	locations: [],
	departments: [],
	vendors: [],
	
	currencies: [],
	combinations: [],
}

const modules = {
	purchasing,
	stockroom,
	patients,
	frontdesk,
	cashregister,
	administration,
	pharmacist,
	reports,
	currency,
	shared
}

const getters = {
	workerRole: (state) => (name) => {
		return state.worker.roles ? state.worker.roles.some(role => role == name) : false
	},
	getWingByCode: (state) => (id) => {
		return state.wings.find((wing) => wing.id == id)
	},
	getItemCategoryByCode: (state) => (id) => {
		return state.categories.find((cat) => cat.id == id)
	},
}

const actions = {
	// SETTINGS
	getApplications({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/applications/get/`)
				.then((response) => {
					const applications = response.data
					commit("set_applications", applications)
					resolve(applications)
				})
				.catch((errors) => {
					commit("set_applications", [])
					reject(errors.response.data)
				})
		})
	},
	getFeaturConfig({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/features/get/`)
				.then((response) => {
					const feature_settings = response.data
					commit("set_feature_settings", feature_settings)
					resolve(feature_settings)
				})
				.catch((errors) => {
					commit("set_feature_settings", [])
					reject(errors.response.data)
				})
		})
	},
	getMonitoringFeatureConfig({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/features/monitoring/get/`)
				.then((response) => {
					const monitoring_settings = response.data
					commit("set_monitoring_settings", monitoring_settings)
					resolve(monitoring_settings)
				})
				.catch((errors) => {
					commit("set_monitoring_settings", [])
					reject(errors.response.data)
				})
		})
	},
	// WORKERCODE
	startSessionWorker({ commit }, workercode) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/workercode/session/${workercode}/start/`)
				.then((response) => {
					const worker = response.data
					commit("set_worker", worker)

					resolve(worker)
				})
				.catch((errors) => {
					commit("set_worker", {})
					reject(errors.response.data)
				})
		})
	},
	endSessionWorker({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/workercode/session/end/")
				.then((response) => {
					commit("set_worker", {})
					resolve(response.data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	//CURRENCIES
	getCurrencies({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/currency/get/")
				.then((response) => {
					const currencies = response.data
					commit("set_currencies", currencies)
					resolve(currencies)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getCurrencyCombinations({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/currency/combination/get/")
				.then((response) => {
					const combinations = response.data
					commit("set_combinations", combinations)
					resolve(combinations)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	// INITAL DATA
	getItemTypes({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/itemtypes/get/")
				.then((response) => {
					const itemtypes = response.data
					resolve(itemtypes)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getCategories({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/categories/get/")
				.then((response) => {
					const categories = response.data
					commit("set_categories", categories)
					resolve(categories)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getUnits({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/units/get/")
				.then((response) => {
					const units = response.data
					commit("set_units", units)
					resolve(units)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getWings({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/wings/get/")
				.then((response) => {
					const wings = response.data
					commit("set_wings", wings)
					resolve(wings)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getLocations({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/locations/get/")
				.then((response) => {
					const locations = response.data
					commit("set_locations", locations)
					resolve(locations)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getDepartments({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/departments/get/")
				.then((response) => {
					const departments = response.data
					commit("set_departments", departments)
					resolve(departments)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	getVendors({commit}){
		return new Promise((resolve,reject) => {
			axios
				.get("/api/pharmacy/vendors/get/")
				.then((response) => {
					const vendors = response.data
					commit("set_vendors", vendors)
					resolve(vendors)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},

	// ITEMS
	filterItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/items/filter/", {
					params: data,
				})
				.then((response) => {
					const items = response.data
					resolve(items)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterStockroomItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockroomitems/filter/", {
					params: data,
				})
				.then((response) => {
					const stockroomitems = response.data
					resolve(stockroomitems)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterFrontdeskItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/frontdeskitems/filter/", {
					params: data,
				})
				.then((response) => {
					const frontdeskitems = response.data
					resolve(frontdeskitems)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterStockroomItemBatches({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockroomitem/batches/filter/", {
					params: data,
				})
				.then((response) => {
					const stockroomitembatches = response.data
					resolve(stockroomitembatches)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterFrontdeskItemBatches({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/frontdeskitem/batches/filter/", {
					params: data,
				})
				.then((response) => {
					const frontdeskitems = response.data
					resolve(frontdeskitems)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getStockroomItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/stockroomitem/${data}/get/`)
				.then((response) => {
					const stockroomitem = response.data
					// commit("set_current_stockroomitem", stockroomitem)
					resolve(stockroomitem)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getFrontdeskItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/frontdeskitem/${data}/get/`)
				.then((response) => {
					const frontdeskitem = response.data
					// commit("set_current_frontdeskitem", frontdeskitem)
					resolve(frontdeskitem)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getStockroomItemByBarcode({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/scanner/stockroomitem/barcode/${data}/get/`)
				.then((response) => {
					const stockroomitem = response.data
					// commit("set_current_stockroomitem", stockroomitem)
					resolve(stockroomitem)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getFrontdeskItemByBarcode({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/scanner/frontdeskitem/barcode/${data}/get/`)
				.then((response) => {
					const frontdeskitem = response.data
					// commit("set_current_frontdeskitem", frontdeskitem)
					resolve(frontdeskitem)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updateStockroomItem({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/stockroomitem/${data.stockitem_id}/update/`, data)
				.then((response) => {
					const stockroomitem = response.data
					commit("set_current_stockroomitem", stockroomitem)
					resolve(stockroomitem)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	updateFrontdeskItem({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/frontdeskitem/${data.stockitem_id}/update/`, data)
				.then((response) => {
					const frontdeskitem = response.data
					commit("set_current_frontdeskitem", frontdeskitem)
					resolve(frontdeskitem)
				}).catch((errors) => {
					console.log(errors)
					reject(errors.response.data)					
				})
		})
	},
	getDistributionStatistics({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.get(`/api/pharmacy/stockroomitem/${data.stockitem_id}/distribution/get/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				}).catch((errors) => {
					console.log(errors)
					reject(errors.response.data)					
				})
		})
	},
	// RULES
	filterItemCompensations({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockitem/rules/compensations/filter/", {
					params: data,
				})
				.then((response) => {
					const rules = response.data
					resolve(rules)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createItemCompensation({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.post(`/api/pharmacy/stockitem/rules/compensations/create/`, data)
				.then((response) => {
					const stockroomitem = response.data
					commit("set_current_stockroomitem", stockroomitem)
					resolve(stockroomitem)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	updateItemCompensation({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/stockitem/rules/compensations/update/`, data)
				.then((response) => {
					const stockroomitem = response.data
					commit("set_current_stockroomitem", stockroomitem)
					resolve(stockroomitem)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},

	// VENDORS
	filterTemporaryVendors({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockitem/vendor/temporary/filter/", {
					params: data,
				})
				.then((response) => {
					const frontdeskitems = response.data
					resolve(frontdeskitems)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getTemporaryVendorItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/stockitem/${data.stockitem_id}/temporary-vendoritem/${data.vendor_id}/get/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterVendorPriceHistory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockitem/vendor/price/log/filter/", {
					params: data,
				})
				.then((response) => {
					const frontdeskitems = response.data
					resolve(frontdeskitems)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterExemptions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockitem/exemption/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	
	// ORDERS
	filterOrders({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/orders/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterOrderItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/orderitems/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// TRANSFERS
	filterTransferRequests({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/transfer/prescription/filter/`, {
					params: data,
				})
				.then((response) => {
					const items = response.data
					resolve(items)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterStockroomOrders({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/transfer/order/filter/`, {
					params: data,
				})
				.then((response) => {
					const items = response.data
					resolve(items)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// PRESCRIPTIONS
	filterPrescriptions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/prescriptions/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterPrescriptionItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/prescriptionitems/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getPrescriptionByBarcode({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/scanner/prescription/barcode/${data}/get/`)
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	validatePrescriptionItemByBarcode({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/scanner/prescription/${data.id}/validate/`, data)
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	validatePrescriptionItemBySelection({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/scanner/prescription/prescriptionitem/${data.prescriptionitem.id}/validate/`, data)
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// RECEIPTS
	filterReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/receipts/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterReceiptItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/receiptitems/filter/", {
					params: data,
				})
				.then((response) => {
					const orders = response.data
					resolve(orders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// INVENTORY
	filterInventory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/inventory/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInventoryItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/inventoryitems/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getActiveInventory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/inventory/get/`, {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},		
	// INVOICES
	filterInvoices({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/invoices/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	
	//DEBITPAYMENTS 
	filterDebitPayments({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/debit-payments/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	// LOG
	filterItemLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/stockitem/log/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// PATIENTS
	filterPatients({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/patients/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
	set_applications(state, applications) {
		state.applications = applications
	},
	set_feature_settings(state, settings) {
		state.feature_settings = settings
	},
	set_monitoring_settings(state, settings) {
		state.monitoring_settings = settings
	},
	set_worker(state, worker) {
		state.worker = worker
	},
	set_currencies(state, currencies) {
		state.currencies = currencies
	},
	set_combinations(state, combinations) {
		state.combinations = combinations
	},
	set_current_stockroomitem(state, stockroomitem) {
		state.currentStockroomitem = stockroomitem
	},
	set_current_frontdeskitem(state, frontdeskitem) {
		state.currentFrontdeskitem = frontdeskitem
	},
	set_categories(state, categories) {
		state.categories = categories
	},
	set_units(state, units) {
		state.units = units
	},
	set_wings(state, wings) {
		state.wings = wings
	},
	set_locations(state, locations) {
		state.locations = locations
	},
	set_departments(state, departments) {
		state.departments = departments
	},
	set_vendors(state, vendors) {
		state.vendors = vendors
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules
}