import axios from "axios"

const state = {}

const getters = {}

const actions = {	
	// PRODUCTS
	createGP({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/product/gp/create/`, data.data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createOTC({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/product/otc/create/`, data.data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createWP({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/product/wp/create/`, data.data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	// ORDERS
	changeOrderStatus({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.post(`/api/pharmacy/${data.destination}/order/${data.form.id}/update/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	updateVendorItemFromOrder({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/order/orderitem/stockitem/${data.stockitem_id}/vendoritem/update/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/order/orderitem/create/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	updateOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/order/orderitem/${data.form.id}/update/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	updateMultipleOrderItems({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/order/orderitems/update/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	returnOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/order/orderitem/${data.form.id}/return/`)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	returnMultipleOrderItems({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/order/orderitems/return/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	deleteOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.delete(`/api/pharmacy/${data.destination}/order/orderitem/${data.form.id}/delete/`)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	deleteMultipleOrderItems({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.post(`/api/pharmacy/${data.destination}/order/orderitems/delete/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	relocateOrderitem({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/order/orderitem/${data.form.id}/relocate/`)
				.then((response) => {
					const item = response.data
					resolve(item)
				}).catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	// BATCHES
	updateBatch({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/batch/${data.id}/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	deleteBatch({ commit }, data) {
		return new Promise((resolve,reject) => {
			axios
				.delete(`/api/pharmacy/${data.destination}/batch/${data.id}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	// TRANSFER
	createTransferStockroom({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/transfer/stockitem/${data.stockitem_id}/stockroom/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createTransferFrontdesk({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/transfer/stockitem/${data.stockitem_id}/frontdesk/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},

	createTransferRequest({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.post(`/api/pharmacy/${data.destination}/transfer/create/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	deleteTransferRequestItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/transfer/item/${data.form.id}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
	createStockroomOrder({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.post(`/api/pharmacy/${data.destination}/transfer/order/create/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	updateStockroomOrder({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/transfer/order/${data.form.id}/update/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	updateStockroomOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/transfer/order/item/${data.form.id}/update/`, data.form)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	deleteStockroomOrderItem({commit}, data){
		return new Promise((resolve,reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/transfer/order/item/${data.form.id}/delete/`)
			.then((response) => {
				const results = response.data
				resolve(results)
			}).catch((errors) => {
				reject(errors.response.data)					
			})
		})
	},
	// PRESCRIPTIONS
	validatePrescriptionItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/${data.destination}/patient/${data.patient}/prescription/item/validate/`, {insurance:data.insurance ,form:data.form})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	logValidationActions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/validate/actions/log/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updatePrescription({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/prescription/${data.form.id}/update/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updatePrescriptionItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/prescription/item/${data.form.id}/update/`, data)
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	updatePrescriptionItemPrices({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.put(`/api/pharmacy/${data.destination}/prescription/item/${data.form.prescriptionitem_id}/prices/update/`, data)
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	deletePrescription({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/prescription/${data.prescription}/delete/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deletePrescriptionItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/prescription/item/${data.prescriptionitem}/delete/`, data.form)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	// INVENTORY
	updateInventory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/inventory/${data.inventory}/update/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updateInventoryItems({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/inventory/${data.inventory}/items/update/`, data.data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	// DEBIT PAYMENTS
	updateDebitPayments({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/${data.destination}/debit-payments/update/`, data.data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}