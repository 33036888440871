import store from "@/store"
import i18n from '@/i18n'

// Nested Route Middleman
const Middleman = () => import("@/views/pharmacy/help/Middleman")

// Containers
const FrontdeskContainer = () => import("@/views/pharmacy/frontdesk/container/DefaultContainer")

// Views
const FrontdeskIndex = () => import("@/views/auth/Index")
const FrontdeskAssortments = () => import("@/views/pharmacy/frontdesk/views/Assortments")
const FrontdeskPatients = () => import("@/views/pharmacy/frontdesk/views/Patients")
const FrontdeskNewPatients = () => import("@/views/pharmacy/frontdesk/views/NewPatient")
const FrontdeskInternalTransfer = () => import("@/views/pharmacy/frontdesk/views/InternalTransfer")
const FrontdeskTransferRequest = () => import("@/views/pharmacy/frontdesk/views/TransferRequest")
const FrontdeskTransferOrder = () => import("@/views/pharmacy/frontdesk/views/TransferOrder")
const FrontdeskInventory = () => import("@/views/pharmacy/frontdesk/views/Inventory")
const FrontdeskReports = () => import("@/views/pharmacy/frontdesk/views/Reports")
const FrontdeskNewPrescription = () => import("@/views/pharmacy/frontdesk/views/NewPrescription")
const FrontdeskReservedPrescription = () => import("@/views/pharmacy/frontdesk/views/ReservedPrescription")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

export default [
    {
      path: "/frontdesk",
      component: FrontdeskContainer,
      meta: {
        label: i18n.t('routes.frontdesk'),
      },
      children: [
        {
          path: "",
          component: FrontdeskIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortments",
          component: FrontdeskAssortments,
          beforeEnter: requireAuthenticated,
          meta: {
            requiresAuth: true,
            roleRequired: ["assortments:viewer", "assortments:editor"],
            label: i18n.tc('routes.assortments', 2),
          },
        },
        {
          path: "patients",
          component: FrontdeskPatients,
          beforeEnter: requireAuthenticated,
          meta: {
            requiresAuth: true,
            roleRequired: ["patients:viewer", "patients:editor"],
            label: i18n.tc('routes.patients', 1),
          },
        },
        {
          path: "patients/create",
          component: FrontdeskNewPatients,
          beforeEnter: requireAuthenticated,
          meta: {
            requiresAuth: true,
            roleRequired: ["patients:editor"],
            label: i18n.t('routes.new-patients'),
          },
        },
        {
          path: "inventory",
          component: FrontdeskInventory,
          beforeEnter: requireAuthenticated,
          meta: {
            requiresAuth: true,
            roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
            label: i18n.t('routes.inventory'),
          },
        },
        {
            path: "reports",
            component: FrontdeskReports,
            beforeEnter: requireAuthenticated,
            meta: {
                requiresAuth: true,
                roleRequired: ["report:viewer"],
                label: i18n.tc('routes.reports', 2),
            },
        },
        {
          path: "transfers",
          component: Middleman,
          redirect:  '/',
          meta: {
            roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
            label: i18n.tc('routes.transfers'),
          },
          children: [
            {
              path: "internal",
              component: FrontdeskInternalTransfer,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
                label: i18n.t('routes.internal'),
              },
            },
            {
              path: "request",
              component: FrontdeskTransferRequest,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
                label: i18n.tc('routes.request'),
              },
            },
            {
              path: "orders",
              component: FrontdeskTransferOrder,
              beforeEnter: requireAuthenticated,
              meta: {
                roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
                label: i18n.tc('routes.orders', 2),
              },
            },
          ]
        },
        {
          path: "prescriptions",
          component: Middleman,
          redirect:  '/',
          meta: {
            roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
            label: i18n.tc('routes.prescriptions', 2),
          },
          children: [
            {
              path: "new",
              component: FrontdeskNewPrescription,
              beforeEnter: requireAuthenticated,
              meta: {
                requiresAuth: true,
                roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
                label: i18n.t('routes.new'),
              },
            },
            {
              path: "reserved",
              component: FrontdeskReservedPrescription,
              beforeEnter: requireAuthenticated,
              meta: {
                requiresAuth: true,
                roleRequired: ["frontdesk:viewer", "frontdesk:editor"],
                label: i18n.t('routes.reserved'),
              },
            },
          ]
        },
      ],
    },
]
