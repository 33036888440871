import axios from "axios"

const state = {
	genders: [],
	id_types: [],
	ethnicities: [],
	birthPlaces: [],
	phoneTypes: [],
	companies: [],
	doctors: []
}

const getters = {
	getDoctorById: (state) => (id) => {
		return state.doctors.find((doctor) => doctor.id == id)
	} ,
}

const actions = {
	getPhonesTypes({ commit }) {
		return new Promise((resolve, reject) => {
			const phoneTypes = [
				{ id: "M", name: "Mobile" },
				{ id: "P", name: "Landline" },
			]
			commit("set_phoneTypes", phoneTypes)
			resolve(phoneTypes)
		})
	},
	getGenders({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/patients/demographics/genders/")
				.then((response) => {
					const genders = response.data
					commit("set_genders", genders)
					resolve(genders)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getIdTypes({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/patients/demographics/idtypes/")
				.then((response) => {
					const id_types = response.data
					commit("set_id_types", id_types)
					resolve(id_types)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getEthnicities({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/patients/demographics/ethnicities/")
				.then((response) => {
					const ethnicities = response.data
					commit("set_ethnicities", ethnicities)
					resolve(ethnicities)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	getBirthPlaces({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/patients/demographics/birthplaces/")
				.then((response) => {
					const birthPlaces = response.data
					commit("set_birthPlaces", birthPlaces)
					resolve(birthPlaces)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	getCompanies({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/patients/insurance/companies/")
				.then((response) => {
					const companies = response.data
					commit("set_companies", companies)
					resolve(companies)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDoctors({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/patients/doctors/`)
				.then((response) => {
					const doctors = response.data
					commit("set_doctors", doctors)
					resolve(doctors)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
	set_genders(state, genders) {
		state.genders = genders
	},
	set_id_types(state, id_types) {
		state.id_types = id_types
	},
	set_ethnicities(state, ethnicities) {
		state.ethnicities = ethnicities
	},
	set_birthPlaces(state, birthPlaces) {
		state.birthPlaces = birthPlaces
	},
	set_phoneTypes(state, phoneTypes) {
		state.phoneTypes = phoneTypes
	},
	set_companies(state, companies) {
		state.companies = companies
	},
	set_doctors(state, doctors) {
		state.doctors = doctors
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
