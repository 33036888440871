<template>
	<div>
		<b-row v-show="showItemsPerPage" class="mb-3">
			<b-col cols="auto" class="my-1">
				<b-input-group :append="`${navLabel} per page`" class="mb-2 mr-sm-2 mb-sm-0">
					<b-form-select :plain="true" :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
		</b-row>
		<b-table ref="table" :id="id" :responsive="responsive" show-empty :caption-top="captionTop" :fixed="fixed" :small="small" :head-variant="headVariant" :hover="hover" :bordered="bordered" :outlined="outlined" :striped="striped" :dark="dark" :tbody-tr-class="rowClass" :selectable="selectable" :select-mode="selectMode" selectedVariant="primary" @row-clicked="rowClicked" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" :items="provider" :fields="fields" :filter-function="filter" :busy.sync="isBusy" :no-sort-reset="true" :sort-by.sync="sortedBy" :sort-desc.sync="sortedDesc" @refreshed="onRefreshed" :no-local-sorting="true" :no-local-filtering="true">
			<template v-slot:thead-top>
				<b-tr v-show="showFilters">
					<b-th v-for="(field, index) in fields" :key="index">
						<template v-if="Object.keys(field).includes('filter_type')">
							<template v-if="field.filter_type == 'calculation'">
								<b-form-input autocomplete="off" v-if="Object.keys(filters).includes(field.key)" :placeholder="field.placeholder" v-model="filters[field.key]" />
							</template>

							<template v-if="field.filter_type == 'select'">
								<select-box :id="'filter-' + field.key" :plain="true" :show-first="false" :disabled="field.disabled" :items="field.filter_values" @input="filter" v-model="filters[field.key]" />
							</template>
						</template>
						<template v-else>
							<b-form-input type="search" autocomplete="off" v-if="Object.keys(filters).includes(field.key)" placeholder="Filter" @keyup.enter="filter" v-model="filters[field.key]" />
						</template>
					</b-th>
				</b-tr>
			</template>

			<template v-slot:head()="column">
				<template v-if="column.field.type == 'tooltip'"><i class="fa-solid fa-circle-info fa-lg" v-b-tooltip.hover :title="column.field.tooltip" /></template>
				<template v-else-if="column.field.type == 'icon'"> <i :class="`fa ${column.field.icon}`" /> {{ column.label }} </template>
				<template v-else>{{ column.label }}</template>
			</template>

			<template v-for="slot in customSlots" v-slot:[`cell(${slot.key})`]="data">
				<slot :name="`slot-${slot.key}`" :data="data"></slot>
			</template>

			<template v-slot:cell(actions)="row">
				<slot name="actions" :row="row"></slot>
			</template>

			<div slot="table-busy" class="text-center text-danger my-2">
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</div>

			<div slot="empty" class="text-center my-2">
				<slot name="slot-empty"> No {{ navLabel }} found </slot>
			</div>
			<div slot="emptyfiltered" class="text-center my-2">
				<slot name="slot-emptyfiltered"> No {{ navLabel }} found matching your criteria </slot>
			</div>

			<template v-slot:table-caption>
				<slot name="slot-caption"></slot>
			</template>

			<template v-slot:row-details="row">
				<slot name="slot-row-details" :row="row"></slot>
			</template>

			<template v-slot:custom-foot>
				<slot name="slot-custom-footer"></slot>
			</template>
		</b-table>
		<b-row v-show="showItemsNavigation">
			<b-col cols="auto" class="mr-auto mt-3 text-left"> Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }} {{ navLabel }} </b-col>
			<b-col cols="auto" class="mt-3 text-right">
				<b-pagination prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseTableExtension from "./BaseTableExtension"

export default BaseTableExtension.extend({
	name: "model-table",
	props: {
		provider: {
			type: Function,
		},
		startIndex: {
			default() {
				return 0
			},
		},
		endIndex: {
			default() {
				return 0
			},
		},
		totalRows: {
			default() {
				return 0
			},
		},
	},
	watch: {
		perPage(val) {
			this.$emit("per-page", val)
		},
	},
})
</script>
