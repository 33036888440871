var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vc-date-picker',{attrs:{"disabled-dates":_vm.disableDates,"available-dates":_vm.availableDates,"input-debounce":1500,"select-attribute":_vm.selectAttribute,"mode":"single","locale":"nl-NL","disabled":_vm.disabled,"attributes":_vm.attributes,"popover":{
		placement: _vm.placement || 'right',
		visibility: _vm.visibility || 'click',
	},"input-props":{
		class: 'form-control',
		placeholder: _vm.placeholder,
	}},on:{"dayclick":_vm.onDayClick,"input":_vm.handleInput},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }