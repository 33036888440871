import axios from "axios"

const state = {}

const getters = {}

const actions = {
	printStockroomTransferLabels({commit}, data){
		return new Promise((resolve,reject) => {
			axios
				.post(`/api/pharmacy/stockroom/transfer/${data.transfer}/labels/print/`, data.options)
				.then((response) => {
					const results = response.data
					resolve(results)
				}).catch((errors) => {
					reject(errors.response.data)					
				})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}