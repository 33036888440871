<template>
	<div>
		<b-row v-show="showItemsPerPage" class="mb-3">
			<b-col cols="auto" class="my-1">
				<b-input-group :append="`${navLabel} per page`" class="mb-2 mr-sm-2 mb-sm-0">
					<b-form-select :plain="true" :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
		</b-row>
		<b-table ref="table" :id="id" responsive show-empty :caption-top="captionTop" :fixed="fixed" :small="small" :head-variant="headVariant" :hover="hover" :bordered="bordered" :outlined="outlined" :striped="striped" :dark="dark" :tbody-tr-class="rowClass" :selectable="selectable" :select-mode="selectMode" selectedVariant="success" @row-clicked="rowClicked" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" :items="items" :fields="fields" :filter-function="filter" :busy.sync="isBusy" :no-sort-reset="true" :sort-by.sync="sortedBy" :sort-desc.sync="sortedDesc" @refreshed="onRefreshed">
			<template v-slot:thead-top="data">
				<b-tr v-show="showFilters">
					<b-th v-for="(field, index) in fields" :key="index">
						<b-form-input autocomplete="off" v-if="Object.keys(filters).includes(field.key)" placeholder="Filter" @keyup.enter="filter" v-model="filters[field.key]" />
					</b-th>
				</b-tr>
			</template>

			<template v-slot:top-row="data" columns="5">
				<slot name="slot-custom-top-row"></slot>
			</template>

			<template v-for="slot in customSlots" v-slot:[`cell(${slot.key})`]="data">
				<slot :name="`slot-${slot.key}`" :data="data"></slot>
			</template>

			<!-- A virtual composite column -->
			<template v-slot:cell(actions)="row">
				<slot name="actions" :row="row"></slot>
			</template>

			<div slot="table-busy" class="text-center text-danger my-2">
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</div>

			<div slot="empty" class="text-center my-2" slot-scope="scope">
				<!-- {{ scope.emptyText }} -->
				<slot name="slot-empty"> No {{ navLabel }} found </slot>
			</div>
			<div slot="emptyfiltered" class="text-center my-2" slot-scope="scope">
				<!-- {{ scope.emptyFilteredText }} -->
				<slot name="slot-emptyfiltered"> No {{ navLabel }} found matching your criteria </slot>
			</div>

			<template v-slot:table-caption>
				<slot name="slot-caption"></slot>
			</template>

			<template v-slot:row-details="row">
				<slot name="slot-row-details" :row="row"></slot>
			</template>
		</b-table>
		<b-row v-show="showItemsNavigation">
			<b-col cols="auto" class="mr-auto mt-3 text-left"> Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }} {{ navLabel }} </b-col>
			<b-col cols="auto" class="mt-3 text-right">
				<b-pagination prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseTableExtension from "./BaseTableExtension"

export default BaseTableExtension.extend({
	name: "base-table",
	computed: {
		totalRows() {
			return this.items.length
		},
		startIndex() {
			return (this.currentPage - 1) * this.perPage + 1
		},
		endIndex() {
			var end = this.totalRows
			if (this.perPage < this.totalRows) {
				end = this.perPage * this.currentPage
				if (end > this.totalRows) {
					end = this.totalRows
				}
			}
			return end
		},
	},
})
</script>
