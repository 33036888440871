<template>
	<b-form-group :label="label">
		<template v-slot:description>
			<span :class="descriptionClass">
				<strong>{{ description }}</strong>
			</span>
		</template>
		<label class="sr-only" :for="id">{{ name }}</label>
		<b-input-group :prepend="name" class="mb-2 mr-sm-2 mb-sm-0 text-success">
			<slot></slot>
			<template #append>
				<slot name="append"></slot>
			</template>
		</b-input-group>
		<b-form-valid-feedback :state="!state"><slot name="slot-valid-feedback"></slot></b-form-valid-feedback>
		<b-form-invalid-feedback :tooltip="tooltip" :state="state"><slot name="slot-invalid-feedback"></slot></b-form-invalid-feedback>
	</b-form-group>
</template>
<script>
export default {
	name: "form-group",
	props: {
		id: {
			required: true,
		},
		name: {
			default() {
				return ""
			},
		},
		description: {
			default() {
				return null
			},
		},
		label: {
			default() {
				return null
			},
		},
		descriptionClass: {
			default() {
				return "text-muted"
			},
		},
		tooltip: {
			default() {
				return false
			},
		},
		state: {
			default() {
				return null
			},
		},
	},
}
</script>
