import axios from "axios"

const state = {}

const getters = {}

const actions = {
	createInventory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/administration/inventory/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyTransferGrouping({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/transfers/grouping/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyTransfers({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/transfers/paginated/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyPrescriptionGrouping({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/prescriptions/grouping/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyPrescriptions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/prescriptions/paginated/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyCashregisters({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/cashregisters/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getNATReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/cashregisters/${data.shift}/nat/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getSalesReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/cashregisters/${data.shift}/sales/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getStockroomOrders({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/cashregisters/${data.shift}/orders/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterCashregisterActions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/daily/cashregisters/actions/filter/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	
	updateCashregisterAction({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/daily/cashregisters/action/${data}/update/`)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	endCashRegisterShift({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/daily/cashregisters/shift/${data.id}/end/`, data)
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updateCashregisterFinance({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/daily/cashregisters/${data.shift}/finances/update/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/administration/invoices/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/invoices/${data.id}/get/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	resyncInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/invoices/${data.id}/resync/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	updateInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/invoices/${data.id}/update/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	approveInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/invoices/${data.id}/approve/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	reviseInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/invoices/${data.id}/revise/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	processInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/administration/invoices/${data.id}/process/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deleteInvoice({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.delete(`/api/pharmacy/administration/invoices/${data.id}/delete/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInvoiceOpenRecords({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/invoices/${data.invoice}/open/filter/`, {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInvoiceRemovedRecords({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/invoices/${data.invoice}/removed/filter/`, {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInvoiceLockedRecords({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/invoices/${data.invoice}/locked/filter/`, {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterInvoiceRevisedRecords({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/invoices/${data.invoice}/revised/filter/`, {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getCustomFinancialBalance({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/financial-balance/custom/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getDailyFinancialBalance({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/financial-balance/daily/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getMonthlyFinancialBalance({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/administration/financial-balance/monthly/get/`, {
					params: data
				})
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createStockroomCustomTransfer({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/administration/custom-transfer/stockroom/${data.stockitem_id}/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createFrontdeskCustomTransfer({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/administration/custom-transfer/frontdesk/${data.stockitem_id}/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createExemptionPeriod({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.post(`/api/pharmacy/administration/exemption/stockitem/${data.stockitem_id}/create/`, data)
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	updateExemptionPeriod({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.put(`/api/pharmacy/administration/exemption/${data.exemption_id}/update/`, data)
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	triggerExemptionPeriod({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.put(`/api/pharmacy/administration/exemption/${data.exemption_id}/trigger/`, data)
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	filterExemptionLog({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.get(`/api/pharmacy/administration/exemption/log/filter/`, {
				params: data,
			})
			.then((response) => {
				const results = response.data
				resolve(results)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}