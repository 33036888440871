import Vue from "vue"

export default Vue.extend({
    data() {
		return {
			show: false,
		}
	},
	methods: {
		onShow() {
			this.show = true
		},
		onCancel() {
			this.$emit("cancel")
			this.show = false
		},
		onOK() {
			this.$emit("submit")
			this.show = false
		},
	},
})