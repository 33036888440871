<template>
        <b-form-file ref="fileUpload" v-model="form.document" @input="onChange" variant="info" size="sm" :no-drop="true" :accept="formats" :placeholder="placeholder" :browse-text="browseText"/>
</template>

<script>
import SingleFileUploadExtension from './SingleFileUploadExtension'

export default SingleFileUploadExtension.extend({
	name:'file-upload-plain',
	props:['placeholder', 'browseText'],
})
</script>