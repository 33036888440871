import axios from "axios"

const state = {
}

const getters = {
}

const actions = {
	uploadFile({ commit }, data) {
		return new Promise((resolve, reject) => {
			let formData = new FormData()

			for (let property in data.form) {
				if (Array.isArray(data.form[property]) && data.form[property].length >= 1) {
					for (let i = 0; i < data.form[property].length; i++) {
						formData.append(property, data.form[property][i])
					}
				} else {
					formData.append(property, data.form[property])
				}
			}

			axios
				.post(`/api/${data.destination}`, formData)
				.then((response) => {
					resolve(response.data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
