<template>
<div class="dropbox" v-show="showFileUpload">
    <loading :active.sync="isBusy" 
        :can-cancel="false" 
        :is-full-page="false"
        loader="dots"></loading>

    <b-form-file class="file-upload" plain multiple ref="fileUpload" v-model="form.document" @input="onChange" :accept="formats"></b-form-file>
    <p v-if="isBusy" class="mb-2"><b>{{$t('actions.uploading-files')}} </b></p>
    <p v-if="!isBusy"><slot/></p>
</div>
</template>
<script>
import Loading from 'vue-loading-overlay'

import MultiFileUploadExtension from './MultiFileUploadExtension'

export default MultiFileUploadExtension.extend({
    name: 'multiple-file-upload-drop-box',
    components: {
      Loading
    }
})
</script>
<style scoped>
.dropbox {
    outline: 2px dashed #c8ced3; /* the dash box */
    outline-offset: -10px;
    background: #fff;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.file-upload input {
    height: 200px !important;
    cursor: pointer;
}

.file-upload {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: #f0f3f5; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
</style>