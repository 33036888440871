<template>
	<vc-date-picker
		:disabled-dates="disableDates"
		:available-dates="availableDates"
		:input-debounce="1500"
		:select-attribute="selectAttribute"
		mode="single"
		locale="nl-NL"
		@dayclick="onDayClick"
		:disabled="disabled"
		:attributes="attributes"
		:popover="{
			placement: placement || 'right',
			visibility: visibility || 'click',
		}"
		:input-props="{
			class: 'form-control',
			placeholder: placeholder,
		}"
		v-model="selected"
		@input="handleInput"
	>
	</vc-date-picker>
</template>
<script>
import moment from "moment"

export default {
	name: "new-date-picker",
	props: {
		value: {
			default() {
				return null
			},
			required: true,
		},
		disabled: {
			default() {
				return false
			},
		},
		placement: {
			default() {
				return "right"
			},
		},
		visibility: {
			default() {
				return "click"
			},
		},
		popover: {
			default() {
				return {
					placement: this.placement,
					visibility: this.visibility,
				}
			},
		},
		attributes: {
			default() {
				return [
					{
						key: "today",
						dot: true,
						dates: new Date(),
						popover: {
							label: "Today",
						},
					},
				]
			},
		},
		placeholder: {
			default() {
				return ""
			},
		},
		availableDates: {
			default() {
				return {
					start: null,
					end: null,
				}
			},
		},
		disableDates: {
			default() {
				return {
					start: null,
					end: null,
				}
			},
		},
		selectAttribute: {
			default() {
				return {
					highlight: "green",
				}
			},
		},
	},
	data() {
		return {
			selected: null,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (val) {
					this.selected = val instanceof Date ? val : moment(val, "YYYY-MM-DD").toDate()
					this.$emit("input", this.formatDate(this.selected))
				} else {
					this.selected = null
					this.$emit("input", null)
				}
			},
		},
	},
	methods: {
		onDayClick(day) {
			this.$emit("change", day)
		},
		handleInput(input) {
			this.$emit("input", input ? this.formatDate(input) : null)
			if (input) this.$emit("change", input)
		},
	},
}
</script>
