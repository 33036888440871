import axios from "axios"

const state = {
	cashregister: {},
}

const getters = {
	shiftStarted: state => Object.entries(state.cashregister).length !== 0,
}

const actions = {
	filterShiftActions({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/shift/actions/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterShiftExpenses({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/shift/expenses/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterProcessedReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/receipts/processed/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterUnProcessedReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/receipts/open/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterReturnedReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/receipts/returned/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterUnReturnedReceipts({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/receipts/unreturned/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterProcessedOrders({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/orders/processed/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	filterOpenOrders({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get("/api/pharmacy/cashregister/orders/open/filter/", {
					params: data,
				})
				.then((response) => {
					const results = response.data
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getCashRegister({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/cashregister/shift/get/`)
				.then((response) => {
					const results = response.data
					commit("set_cashregister", results)
					resolve(results)
				})
				.catch((errors) => {
					console.log(errors)
					commit('set_cashregister', {})
					resolve({})
				})
		})
	},
	startCashRegisterShift({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/shift/start/`, data)
				.then((response) => {
					const results = response.data
					commit("set_cashregister", results)
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	endCashRegisterShift({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/cashregister/shift/${state.cashregister.id}/end/`, data)
				.then((response) => {
					const results = response.data
					commit("set_cashregister", {})
					resolve(results)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	cashDrawerOpened({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/drawer/${state.cashregister.id}/open/`, data)
				.then((response) => {
					const data = response.data
					commit("set_cashregister", data.cashregister)
					resolve(data.action)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createNewReceipt({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/receipts/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createReceiptByScanner({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/scanner/receipts/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getReceipt({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/cashregister/receipts/${data.id}/get/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	processReceipt({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/cashregister/${state.cashregister.id}/receipts/process/`, data)
				.then((response) => {
					const data = response.data
					commit("set_cashregister", data.cashregister)
					resolve(data.receipt)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	printReceiptCopy({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/cashregister/receipts/${id}/print/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	returnReceipt({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
			.put(`/api/pharmacy/cashregister/${state.cashregister.id}/receipts/return/`, data)
			.then((response) => {
				const data = response.data
				commit("set_cashregister", data.cashregister)
				resolve(data.receipt)
			})
			.catch((errors) => {
				reject(errors.response.data)
			})
		})
	},
	printReturnReceiptCopy({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/cashregister/receipts/${id}/return/print/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deleteReceipt({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.delete(`/api/pharmacy/cashregister/receipts/${data.receipt}/delete/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createNewReceiptItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/receipts/${data.receipt}/item/create/`, data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	returnReceiptItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/cashregister/receipts/item/${data.receiptitem}/return/`, data.form)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	deleteReceiptItem({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.delete(`/api/pharmacy/cashregister/receipts/item/${data.receiptitem}/delete/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	createExpense({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/pharmacy/cashregister/expense/${state.cashregister.id}/create/`, data)
				.then((response) => {
					const data = response.data
					commit("set_cashregister", data.cashregister)
					resolve(data.expense)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	processOrder({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/api/pharmacy/cashregister/${state.cashregister.id}/orders/process/`, data)
				.then((response) => {
					const data = response.data
					commit("set_cashregister", data.cashregister)
					resolve(data.order)
				})
				.catch((errors) => {
					console.log(errors)
					reject(errors.response.data)
				})
		})
	},
	printOrderCopy({}, id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/pharmacy/cashregister/orders/${id}/print/`)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	

}

const mutations = {
	set_cashregister(state, cashregister) {
		state.cashregister = cashregister
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}