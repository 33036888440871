import FileUploadExtension from "./FileUploadExtension"

export default FileUploadExtension.extend({
	data() {
		return {
			form: new Form({
				document: null
			})
		}
	},
	methods: {
		onChange(files) {
			if (files.length > 0) {
				this.$emit("uploading")
				this.loading()
				this.$store
					.dispatch("general/uploadFile", { destination: this.destination, form: this.form.data() })
					.then((file) => {
						this.stopLoading()
						this.showFileUpload = this.hideOnDone ? false : true
						this.$emit("uploaded", files)
					})
					.catch((errors) => {
						this.reset()
						this.stopLoading()
						this.$emit("errors", errors)
					})
			}
		},
		reset() {
			this.showFileUpload = true
			this.$refs.fileUpload.reset()
		}
	}
})
