import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import App from "./App"
import router from "./router"
import store from "./store"

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import Form from "@/core/utils/Form"
import { EventBus } from "@/core/utils/EventBus"
import Mixins from "@/core/mixins/Mixins"
import Navigation from "@/core/mixins/Navigation"
import Shortcodes from "@/core/mixins/Shortcodes"
import Components from "@/core/components"
import Directives from "@/core/directives"

import VueScrollTo from "vue-scrollto"
import VCalendar from "v-calendar"
import VueCurrencyInput from 'vue-currency-input'
import i18n from './i18n'
import FlagIcon from 'vue-flag-icon';

window.EventBus = EventBus
window.Form = Form

Vue.mixin(Mixins)
Vue.mixin(Navigation)
Vue.mixin(Shortcodes)

Vue.use(BootstrapVue)
Vue.use(Components)
Vue.use(Directives)
Vue.use(FlagIcon)

Vue.use(VueScrollTo, {
    easing: "ease-in",
    offset: -150,
    x: false,
    y: true
})

Vue.use(VCalendar, {
	componentPrefix: "vc",
	locales: {
		"nl-NL": {
			firstDayOfWeek: 2,
			masks: {
				L: "DD-MM-YYYY",
			},
		},
	},
})

Vue.use(VueCurrencyInput)
Vue.use(i18n)
Vue.use(VueViewer, {
	defaultOptions: {
		zIndexInline: 2021,
		focus: false,
		toolbar: false,
		navbar: false
	}
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount("#app")

