import { mapState, mapGetters } from "vuex"
import moment from "moment"
import currency from 'currency.js'

export default {
	computed: {
		...mapState("user", {
			user: (state) => state.current,
		}),
		...mapState("pharmacy/cashregister", {
			cashregister: (state) => state.cashregister,
		}),
		...mapGetters("user", ["hasRole", "hasSomeRole","isSuperUser", "hasGroup"]),
		...mapGetters("pharmacy", ["workerRole"]),
		getCurrentGroup() {
			return this.user.active_group
		},
		currencyOptions() {
			return {
				locale: 'en-EN',
				currency: 'SRD',
				distractionFree: {
				    hideNegligibleDecimalDigits: true,
				    hideCurrencySymbol: true,
				    hideGroupingSymbol: true
				  },
				autoDecimalMode: true,
				valueAsInteger: false,
				allowNegative: false
		  	}
		}
	},
	methods: {
		goBack() {
            this.$router.go(-1)
        },
		userHasSomeRole(role) {
			return this.hasSomeRole(role)
		},
		userHasRole(role) {
			return this.hasRole(role)
		},
		workercodeHasRole(role) {
			return this.workerRole(role)
		},
		userHasGroup(group) {
			return this.hasGroup(group)
		},
		isActiveUser() {
			return this.user.id ==  this.cashregister.user_id
		},

		// DATES
		reverseFormateDate(date) {
			return moment(date).format("YYYY/MM/DD")
		},
		formatMonth(date) {
			return moment(date, "M").format("MMM").toLowerCase()
		},
		formattedFullMonth(date) {
			return moment(date, "M").format("MMMM")
		},
		formatLocalDate(date) {
			return moment(date, "YYYY/MM/DD").format("DD-MM-YYYY")
		},
		formatDate(date) {
			return moment(date, "YYYY/MM/DD").format("YYYY/MM/DD")
		},
		formattedDate(date) {
			let options = { year: "numeric", month: "short", day: "numeric" }
			return moment(date, "YYYY-MM-DD").toDate().toLocaleDateString("nl-NL", options)
		},
		formatTime(date) {
			return moment(date, "YYYY/MM/DD").format("HH:mm")
		},
		formattedDateTime(datetime) {
			let options = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" }
			return moment(val, "YYYY-MM-DD H:M:S").toDate().toLocaleDateString("nl-NL", options)
		},
		formattedFullDate(date) {
			let options = { year: "numeric", month: "long", day: "numeric" }
			return moment(date, "YYYY-MM-DD").toDate().toLocaleDateString("nl-NL", options)
		},
		formattedFullDateTime(date) {
			let options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" }
			return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate().toLocaleDateString("nl-NL", options)
		},
		formattedWeekday(date) {
			let options = { weekday: "long" }
			return moment(date, "YYYY-MM-DD").toDate().toLocaleDateString("nl-NL", options)
		},

		// CURRENCY + PRICES
		formatToCurrency(number, currency = 'SRD') {
			if (currency || currency !== undefined) {
				return new Intl.NumberFormat('en-EN', { style: 'currency', currency: currency }).format(number)
			}else{
				return new Intl.NumberFormat('en-EN').format(number)
			}
		},
		formatToCurrencyRaw(number, currency='SRD'){
			return currency + ' ' + number
		},
		roundPrice(price) {
			return Math.round((price + Number.EPSILON) * 100) / 100
		},
		ceilPrice(price, number = 0.05) {
			const f = Math.ceil(price / number)
			return this.roundPrice(f * number)
		},

		// COMPONENTS
		toast(title, message, variant, sticky = false, delay = 3000, hideCloseButton = true) {
			this.$bvToast.toast(message, {
				solid: true,
				title: title,
				variant: variant,
				noAutoHide: sticky,
				autoHideDelay: delay,
				noCloseButton: hideCloseButton,
			})
		},
		dialog(title, message, size="md", okTitle = "Confirm", cancelTitle = "Cancel", okVariant = "primary", centered=true) {
			return this.$bvModal.msgBoxConfirm(message, {
					title: title,
					size: size,
					buttonSize: "lg",
					okTitle: okTitle,
					okVariant: okVariant ,
					cancelTitle: cancelTitle,
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: centered,
				})
				.then((value) => {
					return value
				})
				.catch((err) => {
					return err
				})
		},
		okDialog(title, message, size="md", okVariant = "primary", centered=true) {
			return this.$bvModal.msgBoxOk(message, {
					title: title,
					size: size,
					buttonSize: "lg",
					okVariant: okVariant ,
					footerClass: "p-2",
					centered: centered,
				})
				.then((value) => {
					return value
				})
				.catch((err) => {
					return err
				})
		},
		sleep(ms) {
		  return new Promise(resolve => setTimeout(resolve, ms));
		}
	},
}
