<template>
	<b-overlay :show="show" no-wrap>
		<template #overlay>
			<div ref="dialog" tabindex="-1" role="dialog" class="text-center p-3">
				<strong>{{ message }}</strong>
				<div>
					<b-button variant="outline-success" class="mr-3" @click="onOK">OK</b-button>
					<b-button variant="outline-danger" @click="onCancel"> Cancel </b-button>
				</div>
			</div>
		</template>
	</b-overlay>
</template>

<script>
import OverlayExtension from "./OverlayExtension"

export default OverlayExtension.extend({
	name: "dialog-overlay",
	props: {
		message: {
			type: String,
			default() {
				return "Are you sure?"
			},
		},
	},
})
</script>
