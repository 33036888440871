import axios from "axios"

const state = {
	current : {},
	license : {},
	navbar: [],
}

// getters
const getters = {
	isSuperUser: state => state.current.is_superuser,
	hasGroup: (state) => (name) => {
		if (Object.entries(state.current).length !== 0) {
			return state.current.groups.find(group => group == name)
		}
		return false
	},
	hasSomeRole: (state) => (name) => {
		return state.current.roles ? state.current.roles.some(role => role.includes(name)) : false
	},
	hasRole: (state) => (name) => {
		return state.current.roles ? state.current.roles.some(role => role == name) : false
	},
	isAuthenticated: state => Object.entries(state.current).length !== 0,
	checkLicenseExpired: state => {
		const today = new Date()
		const licenseEnd = new Date(state.license)
		
		return (today.toISOString() >= licenseEnd.toISOString())
	},
}
// actions
const actions = {
	setUser: ({commit, state}, user) => {
		commit('set_user', user)
		return state.current
	},

	getLicense({ commit, state }) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/license/`)
				.then((response) => {
					const license = response.data
					commit("set_license", license)
					resolve(license)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},

	current ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get('/api/users/current/user/')
			.then(response => {
				const user = response.data
				commit('set_user', user)
				resolve(user)
			}).catch(errors => {
				commit('set_user', {})
				resolve({})
			})
		})
	},

	getNavBar ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get('/api/users/navbar/')
			.then(response => {
				const navbar = response.data
				commit('set_navbar', navbar)
				resolve(navbar)
			
			}).catch(errors => {
				reject(errors.response.data)
			})
		})
	},

	login ({ commit }, credentials) {
		return new Promise((resolve, reject) => {
            axios.post('/api/auth/login/', credentials)
                .then(response => {
					const user = response.data
					commit('set_user', user)
					resolve(user)
                })
                .catch(errors => {
                    reject(errors.response.data);
                });
		})
	},

	changePassword ({ commit }, credentials) {
		return new Promise((resolve, reject) => {
            axios.post(`/api/auth/change-password/`, credentials)
                .then(response => {
					const user = response.data
					commit('set_user', user)
					resolve(user)
                })
                .catch(errors => {
                    reject(errors.response.data);
                });
		})
	},

	logout ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/logout/')
			.then(response => {
				commit('set_user', {})
				resolve()
			})
			.catch(errors => {
				reject(errors.response.data)
			})

		})
	},
}

// mutations
const mutations = {
	set_user (state, user) {
		state.current = user
	},

	set_license (state, license) {
		state.license = license
	},

	set_navbar (state, navbar) {
		state.navbar = navbar
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}