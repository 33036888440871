import Vue from "vue"
import Router from "vue-router"
import store from "@/store"

import purchasingRoutes from '@/router/modules/purchasing'
import stockroomRoutes from '@/router/modules/stockroom'
import frontdeskRoutes from '@/router/modules/frontdesk'
import cashregisterRoutes from '@/router/modules/cashregister'
import administrationRoutes from '@/router/modules/administration'
import pharmacistRoutes from '@/router/modules/pharmacist'
import reportsRoutes from '@/router/modules/reports'

const Login = () => import('@/views/auth/Login')
const ChangePassword = () => import('@/views/auth/ChangePassword')

const Page404 = () => import("@/views/errors/Page404")
const Page500 = () => import('@/views/errors/Page500')


const requireUnauthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (store.getters['user/isAuthenticated']) {
            next('/', { replace: true })
        } else {
            next()
        }
    })
}

const redirectLogout = (to, from, next) => {
    store.dispatch('user/logout').then(() => next({ path: '/login', replace: true }))
}

const redirectRequired = (to) => { 
	if (store.getters["user/hasGroup"]("purchasing")) {
		return { path: "/purchasing" }
	} else if (store.getters["user/hasGroup"]("stockroom")) {
		return { path: "/stockroom" }
	} else if (store.getters["user/hasGroup"]("frontdesk")) {
		return { path: "/frontdesk" }
	} else if (store.getters["user/hasGroup"]("cashregister")) {
		return { path: "/cashregister" }
	} else if (store.getters["user/hasGroup"]("administration")) {
		return { path: "/administration" }
	} else if (store.getters["user/hasGroup"]("pharmacist")) {
		return { path: "/pharmacist" }
	}else{
        return { path: "/login" }
    }
}

const routesBase = [
	{
		path: "/",
		redirect: redirectRequired,
	},
	{
        path: '/login',
        component: Login,
        beforeEnter: requireUnauthenticated,
    },
	{
        path: '/change-password',
        component: ChangePassword,
    },
    {
        path: '/logout',
        beforeEnter: redirectLogout,
    },
    {
        path: '/404',
        component: Page404
    },
    {
        path: '/500',
        component: Page500
    },
    { path: "*", redirect: "/404" },
]


const routes = routesBase.concat(purchasingRoutes, stockroomRoutes, frontdeskRoutes, cashregisterRoutes, administrationRoutes, pharmacistRoutes, reportsRoutes)
Vue.use(Router)

let router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

export default router
