import store from "@/store"
import i18n from '@/i18n'

// Containers
const PharmacistContainer = () => import("@/views/pharmacy/pharmacist/container/DefaultContainer")

// Views
const PharmacistIndex = () => import("@/views/auth/Index")
const PharmacistAssortments = () => import("@/views/pharmacy/pharmacist/views/Assortments")
const PharmacistPatients = () => import("@/views/pharmacy/pharmacist/views/Patients")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

export default [
    {
      path: "/pharmacist",
      component: PharmacistContainer,
      meta: {
        label: "Pharmacist",
      },
      children: [
        {
          path: "",
          component: PharmacistIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["pharmacist:viewer", "pharmacist:editor"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortments",
          component: PharmacistAssortments,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["assortments:viewer", "assortments:editor"],
            label: i18n.tc('routes.assortments', 2),
          },
        },
        {
          path: "patients",
          component: PharmacistPatients,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["patients:viewer", "patients:editor"],
            label: i18n.tc('routes.patients', 2),
          },
        },
      ]
    },
]
