const update = (el) => {
	setTimeout( ()=>{
		if (el.value) {
			var event = new Event("input")
			el.value = el.value.toUpperCase()
			el.dispatchEvent(event)
		}
	}, 1000 )
}
/*
 * Export our directive
 */
export const uppercase = {
	update
}
