import Vue from "vue"

export default Vue.extend({
	props: {
		formats: {
			default() {
				return ".jpeg, .jpg, .png, .pdf"
			}
		},
		destination: {
			type: String,
			required: true
		},
		hideOnDone: {
			default() {
				return true
			}
		}
	},
	data() {
		return {
			isBusy: false,
			showFileUpload: true,
		}
	},
	methods: {
		addPropertyToForm(prop, value) {
			let data = this.form.data()
			data[prop] = value
			this.form = new Form(data)
		},
		loading() {
			this.isBusy = true
		},
		stopLoading() {
			this.isBusy = false
		}
	}
})
