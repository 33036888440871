import store from "@/store"
import i18n from '@/i18n'

// Containers
const ReportsContainer = () => import("@/views/pharmacy/reports/container/DefaultContainer")

// Views
const ReportsIndex = () => import("@/views/auth/Index")
const ReportsAssortmentOverview = () => import("@/views/pharmacy/reports/views/AssortmentOverview")
const ReportsStockStatistics = () => import("@/views/pharmacy/reports/views/StockStatistics")
const ReportsDistributedStatistics = () => import("@/views/pharmacy/reports/views/DistributedStatistics")
const ReportsExpiredProducts = () => import("@/views/pharmacy/reports/views/ExpiredProducts")
const ReportsInventoryLog = () => import("@/views/pharmacy/reports/views/InventoryLog")
const ReportsOrderitemLog = () => import("@/views/pharmacy/reports/views/OrderitemLog")
const ReportsPrescriptionStatistics = () => import("@/views/pharmacy/reports/views/PrescriptionStatistics")
const ExemptionPeriodReport = () => import("@/views/pharmacy/reports/views/ExemptionPeriodReport")
const ReportsMedicationMonitoringLog = () => import("@/views/pharmacy/reports/views/MedicationMonitoringLog")
const ReportsBTWOverview = () => import("@/views/pharmacy/reports/views/BTWOverview")

const guardRoute = (to, from, next) => {
	let hasRole

    store.dispatch("user/getLicense").then(() => {
		if (store.getters["user/checkLicenseExpired"]) {
			return next({ path: '/404'})
		}
	})

	if (Array.isArray(to.meta.roleRequired)) {
		let array = to.meta.roleRequired
		hasRole = array.some((role) => {
			return store.getters["user/hasRole"](role)
		})
	} else {
		hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
	}

	if (hasRole) return next()
	return next(false)
}

const requireAuthenticated = (to, from, next) => {
    store.dispatch('user/current').then(() => {
        if (!store.getters['user/isAuthenticated']) {
            if (to.fullPath === '/') {
                next({ path: '/login', replace: true })
            }else{
                next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
            }
        } else {
            if (to.matched.some((m) => m.meta.roleRequired)) {
                guardRoute(to, from, next)
            } else {
                next()
            }
        }
    })
}

export default [
    {
      path: "/reports",
      component: ReportsContainer,
      meta: {
        label: i18n.tc('routes.reports', 2),
      },
      children: [
        {
          path: "",
          component: ReportsIndex,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.welcome'),
          },
        },
        {
          path: "assortment/overview",
          component: ReportsAssortmentOverview,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.assortment-overview'),
          },
        },
        {
          path: "assortment/stock-statistics",
          component: ReportsStockStatistics,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.stock-statistics'),
          },
        },
        {
          path: "assortment/distributed-statistics",
          component: ReportsDistributedStatistics,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.distributed-statistics'),
          },
        },
        {
          path: "assortment/expired-products",
          component: ReportsExpiredProducts,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.expired-products'),
          },
        },
        {
          path: "inventory/inventory-log",
          component: ReportsInventoryLog,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.inventory-log'),
          },
        },
        {
          path: "orders/orderitem-log",
          component: ReportsOrderitemLog,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.orderitem-log'),
          },
        },
        {
          path: "prescriptions/prescription-statistics",
          component: ReportsPrescriptionStatistics,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.prescription-statistics'),
          },
        },
        {
          path: "receipts/btw-overview",
          component: ReportsBTWOverview,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.btw-overview'),
          },
        },
        {
          path: "exemption-period/report",
          component: ExemptionPeriodReport,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.exemption-period-report'),
          }
        },
        {
          path: "medication-monitoring/log",
          component: ReportsMedicationMonitoringLog,
          beforeEnter: requireAuthenticated,
          meta: {
            roleRequired: ["reports:viewer"],
            label: i18n.t('routes.monitoring-log'),
          },
        },
      ]
    },
]
